<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>运营数据管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有运营数据" icon="ios-people">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="operationManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <!-- <TabPane label="新增运营数据" icon="md-cloud-upload">
            <div class="form-wrapper">
              <Form
                ref="addRisk"
                :model="newRisk"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="newRisk.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="风险点概述：" prop="risk_content">
                  <Input v-model="newRisk.risk_content" placeholder="" />
                </FormItem>
                <FormItem label="风险点详情说明：" prop="description">
                  <Input
                    v-model="newRisk.description"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="时间：" prop="evaluate_year">
                  <DatePicker
                    type="year"
                    placeholder=""
                    style="width: 200px"
                    v-model="newRisk.evaluate_year"
                  ></DatePicker>
                </FormItem>
                <FormItem label="类别：" prop="sort">
                  <Select
                    v-model="newRisk.sort"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.sortChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="分值：" prop="score">
                  <InputNumber
                    :max="100"
                    :min="1"
                    v-model="newRisk.score"
                  ></InputNumber>
                </FormItem>
                <FormItem label="所属专业：" prop="majorSystem">
                  <Select
                    v-model="newRisk.major_system"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.majorSystemChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="专业子系统：" prop="involveInterface">
                  <Select
                    v-model="newRisk.involve_interface"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.interfaceChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="严重等级：" prop="degreeLevel">
                  <Select v-model="newRisk.degree_level" style="width: 260px">
                    <Option
                      v-for="item in option.degreeLevelChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="整改要求：" prop="correctDemand">
                  <Select v-model="newRisk.correct_demand" style="width: 260px">
                    <Option
                      v-for="item in option.correctDemandChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="解决方案：" prop="solutionProposal">
                  <Input
                    v-model="newRisk.solution_proposal"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="归档编号：" prop="archiveNo">
                  <Input v-model="newRisk.archive_no" placeholder="" />
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addRisk')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addRisk')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane> -->
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含运营基础数据的excel文件：
                  <a href="/public/templates/OperationTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                iurl="/operation_alter"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

export default {
  name: "operationManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      update_count: 0,
      columnsFormatData: [
        {
          title: "线路名称",
          key: "line_name",
          align: "center",
        },
        {
          title: "年度",
          key: "year",
          align: "center",
          width: 120,
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              // h(
              //   "Button",
              //   {
              //     props: {
              //       size: "small",
              //     },
              //     style: { color: "green" },
              //     on: {
              //       click: () => {
              //         this.itemEdit(params.row);
              //       },
              //     },
              //   },
              //   "修改"
              // ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
    };
  },
  watch: {},
  computed: {},
  created() {

  },
  methods: {
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条基础数据信息：",
        content:
          "<p>" +
          "所属线路：" +
          value.line_name +
          "</p>" +
          "<p>" +
          "年度：" +
          value.year +
          "年</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/operation_alter", {
          userToken: userToken,
          askData: "delItem",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}
</style>